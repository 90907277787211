<template>
  <el-select
    v-model="collectorId"
    :style="{ width: widthStyle }"
    class="filter-item"
    clearable
    filterable
    remote
    reserve-keyword
    placeholder="请输入(名称、电话、邮箱)关键词进行搜索"
    :remote-method="collectorRemoteMethod"
    :loading="collectorLoading"
    @change="collectorChange"
  >
    <el-option
      v-for="item in collectorOption"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>
<script>
import { collectorGetList } from "@/api/art";

export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    widthStyle: {
      type: String,
      default: "100%"
    },
    type: {
      type: String
    }
  },
  computed: {},
  components: {},
  filters: {},
  data() {
    return {
      collectorId: this.value, //
      collectorLoading: false, // 查询收藏家的搜索
      collectorOption: [] // 收藏家选项
    };
  },
  created() {
    this.collectorRemoteMethod("");
  },
  watch: {},
  methods: {
    /**
     * 收藏家的选项搜索
     * @param query
     */
    collectorRemoteMethod(query) {
      this.collectorLoading = true;
      const params = {
        page: 1,
        limit: 9999
      };
      if (query) {
        params.keyword = query;
      }
      collectorGetList(params)
        .then(resp => {
          this.collectorOption = resp.data.data || [];
        })
        .finally(() => {
          this.collectorLoading = false;
        });
    },
    collectorChange(value) {
      this.$emit("valueFunc", this.type, value);
    }
  }
};
</script>
<style scoped lang="scss"></style>
